<script setup>
import AppInputTextLabelInside from '@/components/input/AppInputTextLabelInside.vue';
import { defineProps, defineEmits, computed, reactive, onMounted } from 'vue';

const props = defineProps({
    modelValue: String
});

const emit = defineEmits(['update:modelValue']);

const _model = reactive({
    rotulo: 'Outros',
    textoOpcao: ''
});

onMounted(() => {
    const { digitado } = obterDigitado(props.modelValue);

    _model.textoOpcao = digitado;
});

const textoOpcao = computed({
    get: () => {
        const { digitado } = obterDigitado(props.modelValue);
        return `${digitado}`;
    },
    set: (newValue) => {
        _model.textoOpcao = newValue;
        emitirOpcaoOutros();
    }
});

function emitirOpcaoOutros() {
    const opcaoCompleta = `${_model.rotulo}:${_model.textoOpcao}`;
    emit('update:modelValue', opcaoCompleta);
}

function obterDigitado(opcaoComLabel) {
    if (!opcaoComLabel) return { rotulo: '', digitado: '' };
    const [lbl, sobre] = opcaoComLabel.split(':');
    const rotulo = `${lbl}`;
    return { rotulo, digitado: sobre?.trim?.() ?? '' };
}

const _radioModel = computed({
    get: () => {
        return props.modelValue ? (props.modelValue?.startsWith('Outros:') ? props.modelValue : undefined) : undefined;
    },
    set: () => {
        emit('update:modelValue', `Outros:${_model.textoOpcao}`);
    }
});

const radioVal = computed(() => {
    return props.modelValue ? (props.modelValue?.startsWith('Outros:') ? props.modelValue : 'Outros') : null;
});
</script>

<template>
    <div class="flex justify-content-center align-items-center">
        <RadioButton v-bind="$attrs" v-model="_radioModel" :value="radioVal" class="pb-1 m-1" />
        <AppInputTextLabelInside :labelText="`${_model.rotulo}:`" :labelFor="radioVal" v-model="textoOpcao" unstyled :comBorda="!!_radioModel" />
    </div>
</template>
