<script setup>
import { defineProps, defineEmits, computed, watch, inject } from 'vue';
import PerguntaMultiplaEscolha from './PerguntaMultiplaEscolha.vue';
import PerguntaCombo from './PerguntaCombo.vue';
import PerguntaDissertativa from './PerguntaDissertativa.vue';
import PerguntaNumeroInteiro from './PerguntaNumeroInteiro.vue';
import PerguntasECondicionais from './PerguntasECondicionais.vue';
import { v4 as uuidv4 } from 'uuid';

const perguntasSemRespostas = inject('perguntasSemRespostas');

const props = defineProps({
    tipo: String,
    descricao: String,
    opcoes: Array,
    obrigatoria: {
        type: Boolean,
        default: false
    },
    perguntasVinculadas: {
        type: Array,
        default: () => []
    },
    modelValue: [Array, String],
    submitted: {
        type: Boolean,
        default: false
    },
    outros: Boolean
});

const emits = defineEmits(['update:modelValue']);

const componentsMap = {
    CB: PerguntaCombo,
    ME: PerguntaMultiplaEscolha,
    DI: PerguntaDissertativa,
    IN: PerguntaNumeroInteiro
};

const localResposta = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        emits('update:modelValue', newValue);
    }
});

const _perguntasVinculadas = computed({
    get: () => props.perguntasVinculadas,
    set: (newValue) => {
        emits('update:perguntasVinculadas', newValue);
    }
});

const uniqueId = `${uuidv4()}`;

const hasError = computed(() => props.submitted && !localResposta.value && props.obrigatoria);

watch(
    () => hasError.value,
    () => {
        if (hasError.value) {
            perguntasSemRespostas.value.push(uniqueId);
        }
    }
);

const verificarRespostas = (perguntaVinculada) => {
    if (props.tipo !== 'ME') {
        return perguntaVinculada.respostaCondicional === localResposta.value;
    }
    const respostas = localResposta.value ? localResposta.value.split('|') : [];
    return respostas.includes(perguntaVinculada.respostaCondicional);
};
</script>
<template>
    <div class="mb-3">
        <component
            :elementId="uniqueId"
            :is="componentsMap[tipo]"
            :tipo="tipo"
            :descricao="descricao"
            :opcoes="opcoes"
            :obrigatoria="obrigatoria"
            :outros="outros"
            v-model="localResposta"
            :isInvalid="submitted && !localResposta && obrigatoria"
        />
    </div>
    <small v-if="submitted && !localResposta && obrigatoria" class="p-error"> Resposta obrigatória </small>
    <template v-if="_perguntasVinculadas.length">
        <div v-for="(perguntaVinculada, index) in _perguntasVinculadas" :key="index">
            <PerguntasECondicionais
                v-if="verificarRespostas(perguntaVinculada)"
                :tipo="perguntaVinculada.perguntaSesmt.tipo"
                :descricao="perguntaVinculada.perguntaSesmt.descricao"
                :opcoes="perguntaVinculada.perguntaSesmt.opcoes"
                :obrigatoria="perguntaVinculada.obrigatorio"
                :perguntasVinculadas="perguntaVinculada.perguntasVinculadas"
                :submitted="submitted"
                v-model="_perguntasVinculadas[index].resposta"
            />
        </div>
    </template>
</template>
