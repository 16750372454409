<script setup>
import OpcaoOutrosRadio from '@/pages/gestao-risco/components/grid-elaboracao/opcao-outros/OpcaoOutrosRadio.vue';
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
    tipo: String,
    descricao: String,
    opcoes: Array,
    obrigatoria: {
        type: Boolean,
        default: false
    },
    isInvalid: {
        type: Boolean,
        default: false
    },
    elementId: {
        type: String,
        default: ''
    },
    modelValue: [Array, String],
    outros: Boolean
});

const emits = defineEmits(['update:modelValue']);

const localResposta = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        emits('update:modelValue', newValue);
    }
});
const opcoesSemOutros = computed(() => props.opcoes.filter((opcao) => opcao !== 'Outros'));
</script>

<template>
    <div v-if="tipo === 'CB'" class="flex flex-column align-items-start text-right">
        <label :for="`${elementId}`" class="flex gap-1 p-text-center font-medium text-lg mb-3">
            <span>{{ descricao }} </span>
            <span v-if="obrigatoria" class="p-error">*</span>
        </label>
        <div v-for="(opcao, index) in opcoesSemOutros" :key="`${index}`" class="flex my-1">
            <RadioButton :id="`${elementId}`" :class="{ 'p-invalid': isInvalid }" v-model="localResposta" :value="opcao" class="pb-1 m-1" />
            <label :for="`${elementId}`" class="font-medium ml-2 my-auto">{{ opcao }}</label>
        </div>
        <OpcaoOutrosRadio v-if="outros" v-model="localResposta" />
    </div>
</template>
