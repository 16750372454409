<script setup>
import OpcaoOutrosCheckbox from '@/pages/gestao-risco/components/grid-elaboracao/opcao-outros/OpcaoOutrosCheckbox.vue';
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
    tipo: String,
    descricao: String,
    opcoes: Array,
    obrigatoria: {
        type: Boolean,
        default: false
    },
    isInvalid: {
        type: Boolean,
        default: false
    },
    elementId: {
        type: String,
        default: ''
    },
    modelValue: String,
    outros: Boolean
});

const emits = defineEmits(['update:modelValue']);

const localRespostaMultiplaEscolha = computed({
    get: () => {
        if (!props.modelValue) return [];

        return props.modelValue.split('|');
    },
    set: (newValue) => {
        emits('update:modelValue', newValue);
    }
});

function updateValue(value) {
    localRespostaMultiplaEscolha.value = value.join('|');
}

const opcoesSemOutros = computed(() => props.opcoes.filter((opcao) => opcao !== 'Outros'));
</script>

<template>
    <div v-if="tipo === 'ME'" class="flex flex-column align-items-start p-0">
        <label :for="`${elementId}`" class="flex gap-1 p-text-center font-medium text-lg mb-3">
            <span>{{ descricao }} </span>
            <span v-if="obrigatoria" class="p-error">*</span>
        </label>
        <div v-for="(opcao, index) in opcoesSemOutros" :key="index" class="my-1 flex">
            <Checkbox
                :id="`${elementId}`"
                :class="{ 'p-invalid': isInvalid }"
                :modelValue="localRespostaMultiplaEscolha"
                @update:modelValue="updateValue"
                :value="opcao"
                class="pb-1 m-1"
            />
            <label for="outros" class="font-medium ml-2 my-auto">{{ opcao }}</label>
        </div>
        <OpcaoOutrosCheckbox
            v-if="outros"
            :modelValue="localRespostaMultiplaEscolha"
            @update:modelValue="updateValue"
            :class="{ 'p-invalid': isInvalid }"
        />
    </div>
</template>
